<template>
	<div v-if="!!error" class="error">
		{{ message }}
	</div>
</template>

<script>
export default {
	name: 'FormError',
	props: {
		error: String,
		namespace: {
			type: String,
			default: 'errors',
		},
	},
	data() {
		return {
			message: null,
		};
	},
	watch: {
		error: {
			immediate: true,
			handler(newVal) {
				// Attempt localization. If impossible, fall back to raw message from backend
				const keys = [`errors.${newVal}`];
				if (this.namespace) {
					keys.unshift(`${this.namespace}.${newVal}`);
				}

				const key = this.searchValidKey(keys);

				if (key) {
					this.message = this.$t(key);
				} else {
					this.message = newVal;
				}
			},
		},
	},
	methods: {
		searchValidKey(keys) {
			for (const key of keys) {
				if (this.$te(key)) {
					return key;
				}
			}

			return null;
		},
	},
};
</script>

<style scoped>
.error {
	color: #ff0033;
	font-size: 80%;
	text-align: center;
	margin-top: 0.25rem;
	opacity: 1;
	display: block;
	width: 100%;
}
</style>
